import React, {useState, useEffect, useRef} from 'react';
import StatusMessage from '../components/StatusMessage'
import {renderMatches, useNavigate, useParams } from 'react-router-dom';
import {defaultDate, addDefaultDateToValue} from '../services/functions'
import {EMPLOYEES} from '../services/constants'
import serverPost from '../services/serverPost'
import {search, search2} from '../services/search'
import serverFetch, {fetchEmployees} from '../services/serverFetch'
import {allowedToUpdate} from '../services/functions'
import SearchTemplate from '../components/SearchTemplate'
import SearchVatansever from '../components/SearchVatansever'
import FormTemplate from '../components/FormTemplate'
import EditTable from '../components/EditTable'
import {PrintFields, PrintA4, RenderKunduppgifter, RenderFields, addGarantiAkutPrioritet} from '../components/PrintComponentVatansever'
import {useReactToPrint} from 'react-to-print'
import { useSharedState } from '../store';
import { fabClasses } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';


const tableName = 'tbl_service'
const tableNameCustomer = 'tbl_customer'
const searchView = 'view_service'


const fieldNotInList = (value, fld, list) => value[fld] && list.find(it=>it===value[fld])===undefined

const styles = {
    container: {
        marginTop:100,
        display:'flex',
        flexDirection:'column',
        width:'100vw',
        overflow:'auto',
    },
    item: {
        margin:'auto',
    },
    flexContainer:{
        display:'flex',
        paddingTop:20,
        flexDirection: 'row',
        flexWrap:'wrap',  
        //backgroundColor:'rgba(32,32,32,1)',
        alignItems: 'flex-start',
        //justifyContent: 'top',
        //color:'#FFFFFF',
        // height:'calc(100vh \- 50px)'
    },
    row1:{
        flex:1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight:100

    },
    row2:{
        flex:2,
        maxWidth:500,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'pre-wrap',       
        wordWrap: 'break-word',
    },
    button:{
        marginLeft:'5px',
        marginRight:'5px',
        marginBottom:'10px',
        borderWidth:'2px',
        height:50,
        color:'#FFFFFF',
        borderColor:'#FFFF87',
        backgroundColor:'transparent'
    }

}

    /*
    {
        type:'comment',
        ignoreIfValueEmpty:true,
        ignoreSemicolon:true,
        ignoreValue:true,
        label:'Kn',
        name:'kontaktadAvhamtning',
    },
    {
        type:'comment',
        ignoreIfValueEmpty:true,
        ignoreSemicolon:true,
        ignoreValue:true,
        label:'Kunden har kontaktats för avhämtning via',
        name:'kontaktadVia',
    },
    */

const disabledFunc1 = value => {    
    const kontaktad = value.kontaktadAvhamtningAv?value.kontaktadAvhamtningAv.length > 0:false
    return value.disabledSaveSr?(value.disabledSaveSr == 1 || kontaktad):false
}

const disabledFunc1a = value => {    
    let disabled = value.material?true:false
    return disabled || disabledFunc1(value)   

}


const disabledFunc2 = value => {    
    let requiredList = value.ingetMaterial?['tekniker', 'atgarder', 'arbetstid', 'kostnad']:['tekniker', 'atgarder', 'material', 'arbetstid', 'materialkostnad', 'kostnad']
    if (value.felsokning) {
        requiredList = [...requiredList, 'acceptVia', 'acceptInfo']
    }    
    let disabled = false
    requiredList.forEach(name => {
        if (value[name]===undefined || value[name].length === 0) {
            disabled = true
        }
    })
    return disabled || disabledFunc1(value)   
}

const disabledFunc3 = value => {    
    const disabled = value.acceptVia?false:true
    return disabled || disabledFunc1(value)
}

const disabledFunc4 = value => {    
    let disabled = value.kompletteraCheckbox?false:true
    return disabled || disabledFunc1(value)   

}


const preSetValue = val => {
    return {...val, materialkostnad:val.ingetMaterial?'':val.materialkostnad, material:val.ingetMaterial?'':val.material}
}


const formFieldsFunc = emp =>([
    {
        type:'radio',
        radioValues:emp,
        label:'Tekniker',
        name:'tekniker',
        tooltip:'Mottagare av objektet framme vid disken',
        hiddenIf:'annanTekniker',
        disabledFunc:disabledFunc1,
        maxlength:99
    },
    {
        type:'text',
        label:'Tekniker',
        name:'tekniker',
        notHiddenIf:'annanTekniker',
        disabledFunc:disabledFunc1,
        maxlength:99,
    },
    {
        type:'checkbox',
        label:'Annan',
        name:'annanTekniker',
        tooltip:'Kryssa i denna om teknikern är en annan person än de som det finns radio-knappar till',
        checkedIf:'tekniker',
        notCheckedIf:emp, //array
        disabledFunc:disabledFunc1,
    },
    /*
    {
        type:'checkbox',
        label:'Uppdatera kompletterande felbeskrivning',
        name:'kompletteraCheckbox',
        tooltip:'Kryssa i denna rutan om du vill komplettera felbesrivningem med ytterligare uppgifter',
        checkedIf:'kompletterandeFelbeskrivning',
        disabledFunc:disabledFunc1,
    },
    {
        type:'TextArea',
        label:'Kompletterande felbeskrivning',
        name:'kompletterandeFelbeskrivning',
        cols:40,
        minRows:2,
        maxRows:50,
        tooltip:'Kompletterande uppgifter om felet',
        disabledFunc:disabledFunc4,
        maxlength:9999,
    },
    */
    {
        label:'Noteringar',
        type:'TextArea',
        name:'note',
        tooltip:'Allmänna noteringar sökning måste man ange antal timmar eller något annat',
        cols:40,
        minRows:5,
        maxRows:500,
        disabledFunc:disabledFunc1,
        maxlength:50000,
        autoFocus:true,
    },
    {
        type:'date',
        label:'Datum då kunden accepterat reparation',
        name:'acceptDate',
        tooltip:'Datum då kunden accepterat reparation',
        useDefaultDate:true,
        notHiddenIf:'felsokning',
        disabledFunc:disabledFunc1,
    },
    {
        type:'radio',
        label:'Accept via',
        name:'acceptVia',
        radioValues:['SMS', 'MUNTLIGT', 'MAIL'],
        tooltip:'Kunden har kontaktats via denna metod',
        notHiddenIf:'felsokning',
        disabledFunc:disabledFunc1,
    },
    /*
    {
        type:'checkbox',
        label:'Noteringar',
        name:'noteFlag',
        notNull:'note',
        tooltip:'Allmänna noteringar lsökning måste man ange antal timmar eller något annat',
        disabledFunc:disabledFunc1,
    },
    */
    {
        type:'TextArea',
        label:'Kunden har accepterat följande',
        name:'acceptInfo',
        tooltip:'Om kunden begärt felsökning måste man ange antal timmar eller något annat',
        notHiddenIf:'felsokning',
        cols:40,
        minRows:5,
        maxRows:500,
        disabledFunc:disabledFunc3,
        maxlength:999,
    },
    {
        type:'TextArea',
        label:'Utförda åtgärder',
        name:'atgarder',
        tooltip:'Utförda åtgärder',
        cols:40,
        minRows:5,
        maxRows:500,
        disabledFunc:disabledFunc1,
        maxlength:65000,
    },
    {
        type:'checkbox',
        label:'Inget material',
        name:'ingetMaterial',
        tooltip:'Kryssa i denna om du inte använt något material',
        disabledFunc:disabledFunc1a,
        preSetValue
    },
    {
        type:'TextArea',
        label:'Använt material',
        name:'material',
        tooltip:'Använt marterial vid reparation',
        cols:40,
        minRows:5,
        maxRows:500,
        disabledFunc:disabledFunc1,
        hiddenIf:'ingetMaterial',
        maxlength:65000,
    },
    {
        type:'number',
        step:0.5,
        label:'Arbetstid (h)',
        name:'arbetstid',
        txtName:'txtArbetstid',
        tooltip:'Den totala arbetstid som gått åt vid reparationen',
        disabledFunc:disabledFunc1,
    },
    {
        type:'number',
        label:'Materialkostnad (SEK)',
        name:'materialkostnad',
        txtName:'txtMaterialkostnad',
        tooltip:'Kostnad för material',
        disabledFunc:disabledFunc1,
        hiddenIf:'ingetMaterial',
        removedIf:'ingetMaterial'
    },
    {
        type:'number',
        label:'Kostnad (SEK)',
        name:'kostnad',
        txtName:'txtKostnad',
        tooltip:'Totala kostnaden inklusive material',
        disabledFunc:disabledFunc1,
        maxlength:80,
    },
    {
        type:'radio',
        radioValues:emp,
        label:'Kontaktad för avhämting av',
        name:'kontaktadAvhamtningAv',
        tooltip:'Namn på personen som lämnar kontaktat för avhämtning',
        hiddenIf:'annanKontaktadAvhamtningAv',
        disabledFunc:disabledFunc2,
    },
    {
        type:'text',
        label:'Kontaktad för avhämting av',
        name:'kontaktadAvhamtningAv',
        tooltip:'Namn på personen som lämnar tillbaka objektet efter reparation eller kostnadsförslag',
        notHiddenIf:'annanKontaktadAvhamtningAv',
        disabledFunc:disabledFunc2,
        maxlength:79,
    },
    {
        type:'checkbox',
        label:'Annan',
        name:'annanKontaktadAvhamtningAv',
        tooltip:'Om den som utämnar reparerat gods är en annnan person än de 3 ovan nämnda',
        labelStyle:{fontWeight:100},
        checkedIf:'kontaktadAvhamtningAv',
        notCheckedIf:emp, //array
        disabledFunc:disabledFunc2,
        maxlength:79,
    },
    {
        type:'date',
        label:'Kontaktad för avhämtning',
        name:'kontaktadAvhamtning',
        tooltip:'Kunden har kontaktats för avhämtning',
        notHiddenIf:'kontaktadAvhamtningAv',
        useDefaultDate:true,
        disabledFunc:disabledFunc2,
    },
    {
        type:'radio',
        radioValues:['SMS', 'MUNTLIGT', 'MAIL'],
        label:'Kunden har kontaktats för avhämtning via',
        name:'kontaktadVia',
        tooltip:'Kunden har kontaktats för avhämtning via',
        notHiddenIf:'kontaktadAvhamtningAv',
        required:true,
        disabledFunc:disabledFunc2,
    },
])

const fieldsExtra = [
    {
        ignoreIfValueEmpty:true,
        ignoreSemicolon:true,
        ignoreValue:true,
        label:'AKUT PRIORITET',
        name:'akutPrioritet',
        labelStyle:{fontWeight:700, color:'red', lineHeight:1.2}
    },
    {
        ignoreIfValueEmpty:true,
        ignoreSemicolon:true,
        ignoreValue:true,
        label:'GARANTI',
        name:'garanti',
        labelStyle:{fontWeight:700, color:'red', lineHeight:1.2}
    },
    {
        label:'Inlämningsnummer',
        name:'id',
        labelStyle:{lineHeight:1.2}
    },
    {
        label:'Inlämnad',
        name:'inlamnadDate',
        labelStyle:{lineHeight:1.2}
    },
    {
        label:'Utlämnad',
        name:'utlamnadDate',
        labelStyle:{lineHeight:1.2}
    },
    {
        label:'Fabrikat',
        name:'fabrikat',
        labelStyle:{lineHeight:1.2}
    },
    {
        label:'Modell',
        name:'modell',
        labelStyle:{lineHeight:1.2}
    },
    {
        // TextArea:true,
        label:'Felbeskrivning',
        type:'TextArea',
        name:'felbeskrivning',
        cols:40,
        minRows:5,
        maxRows:500,
        disabledFunc:disabledFunc1,
        maxlength:4999,
    },
    {
        TextArea:true,
        label:'Kompletterande felbeskrivning',
        type:'TextArea',
        name:'kompletterandeFelbeskrivning',
        cols:40,
        minRows:2,
        maxRows:200,
        disabledFunc:disabledFunc1,
        maxlength:4999,
    },
]    



const searchFields = [
    {label:'Inlämningsnummer', labelResult: 'Inlämningsnr', name:'id'},
    {label:'För- och efternamn', name:'namn'},
    {label:'Telefonnummer', name:'mobil'},	
    {label:'E-mail', name:'email'},	
]    	

const resultFields = [
    {label:'Nr', labelResult: 'Inlämningsnr', name:'id'},
    {label:'Namn', name:'namn'},
    {label:'Telefon', name:'mobil'},	
    {label:'E-mail', name:'email'},	
    {label:'Fabrikat', name:'fabrikat'},	
    {label:'Modell', name:'modell'},	
    {label:'Utlämnad', name:'utlamnadDate'},	
]    

const resultFieldsAdvanced = [
    {label:'Nr', name:'id'},
    {label:'Namn', name:'namn'},
    {label:'Telefon', name:'mobil'},
    {label:'Mottagare', name:'mottagare'},
    {label:'Felbeskrivning', name:'felbeskrivning'},
    {label:'Inlämnad', name:'inlamnadDate'},
    {label:'Tekniker', name:'tekniker'},
    {label:'Fabrikat', name:'fabrikat'},
    {label:'Modell', name:'modell'},
    {label:'Arbetstid', name:'arbetstid'},
    {label:'Kostnad', name:'kostnad'},	
    {label:'Kontaktad för avhämtning', name:'kontaktadAvhamtning'},	
    {label:'Utlämnad av', name:'utlamnadAv'},	
    {label:'Utlämnad', name:'utlamnadDate'},	
    {label:'Akut', name:'akutPrioritet', boolText:'AKUT', style:{color:'red', fontWeight:900}},	
    {label:'Garanti', name:'garanti', boolText:'GARANTI', style:{color:'red', fontWeight:900}},	
]    	




export const fieldsServiceRapport = [
    {
        emptyRow:true,
    },
    {
        label:'Inlämningsnr.',
        name:'id'
    },
    {
        label:'Utlämnad',
        name:'utlamnadDate'
    },
    {
        emptyRow:true,
    },
    {
        ignoreSemicolon:true,
        ignoreValue:true,
        label:'SERVICERAPPORT',
    },
    {
        emptyRow:true,
    },
    {
        ignoreSemicolon:true,
        ignoreValue:true,
        label:'Kunduppgifter',
    },
    {
        label:'Namn',
        name:'namn',
    },
    {
        label:'Telefonnummer',
        name:'mobil',
    },
    {
        label:'E-mail',
        name:'email',
    },
    {
        emptyRow:true,
    },
    {
        ignoreSemicolon:true,
        ignoreValue:true,
        label:'Inlämnat objekt',

    },
    {
        label:'Fabrikat',
        name:'fabrikat',
    },
    {
        label:'Modell',
        name:'modell',
    },
    {
        ignoreIfValueEmpty:true,
        ignoreSemicolon:true,
        ignoreValue:true,
        label:'Garantiärende',
        name:'garanti',
    },
    {
        emptyRow:true,
    },
    {
        ignoreValue:true,
        label:'Felbeskrivning',
    },
    {
        type:'textarea',
        ignoreLabel:true,
        name:'felbeskrivning',
        valueStyle:{maxWidth:300}
    },
    {
        emptyRow:true,
    },
    {
        label:'Tekniker',
        name:'tekniker',
    },
    {
        ignoreValue:true,
        label:'Utförda åtgärder',
    },
    {
        type:'textarea',
        ignoreLabel:true,
        name:'atgarder',
        valueStyle:{maxWidth:300}
    },
    {
        ignoreValue:true,
        label:'Använt material',
    },
    {
        type:'textarea',
        ignoreLabel:true,
        name:'material',
        valueStyle:{maxWidth:300}
    },
    {
        emptyRow:true,
    },
]

export default () => {
    const params = useParams()
    //const navigate = useNavigate()
    const [list, setList] = useState([])
    const [value, setValue] = useState({})
    const [statusMessage, setStatusMessage] = useState({})
    const [global, setGlobal] = useSharedState({})
    const [sortSequence, setSortSequence] = useState({})
    const [advancedSearch, setAdvancedSearch] = useState(true)
    const [edit, setEdit] = useState(undefined)
    const [formFields, setFormFields] = useState(formFieldsFunc(EMPLOYEES))

    const navigate = useNavigate()

    useEffect(()=>{
        window.scrollTo(0,0)
        setGlobal({...global, pageName:'Servicerapport - Sökning'})
        if (params.id > 0) {
            search(searchView, {id:params.id}, handleSearchReplyClickLine) 
        }
        fetchEmployees(setFormFields, formFieldsFunc);
        // serverFetch('/fetchRows?tableName=tbl_employee', '', '', result=>{setFormFields(formFieldsFunc(result.sort((a,b)=>a.sequenceNumber-b.sequenceNumber).map(it=>it.name)))})
    }, []) 


    const handleStatus = (style, message) => {
        setStatusMessage({style, message})
    }

    const handleClickLine = rec => {
        setGlobal({...global, pageName:'Servicerapport - Inlämnat objekt'})
        search(searchView, {id:rec.id}, handleSearchReplyClickLine) 
    } 

    const handleSaveCallback = reply => {
        const {status, data} = reply

        if (status === 'OK') {
            const disabledSaveSr = (value.kontaktadAvhamtningAv) ?1:0
            setValue({...value, disabledSaveSr})
            handleStatus({backgroundColor:'green'}, undefined)
        } else {
            alert('ERROR save callback data: ' + JSON.stringify(reply.data) + ' data.record: ' + JSON.stringify(data.record))
            const message = 'FELMEDDELANDE: Servicerapporten kunde inte uppdateras'
            handleStatus({backgroundColor:'red'}, message)
        }    
    }       
   

    const handleSave = () => {
        if (value.disabledSaveSr==1) {
            handleStatus({backgroundColor:'green'}, 'Skriv ut utan att spara')
        } else {     
            const disabledSaveSr = value.kontaktadAvhamtningAv?1:0
            handleStatus({backgroundColor:'green'}, 'Uppdaterar servicerapporten i databasen')
            let newValue = addDefaultDateToValue(formFields, value)
            let kontaktadAvhamtning = value.kontaktadAvhamtningAv?value.kontaktadAvhamtning?value.kontaktadAvhamtning:defaultDate():undefined  // Ensure that defaultDate is set
            let acceptDate = value.acceptVia?value.acceptDate?value.acceptDate:defaultDate():undefined // Ensure that default date is set

            let record = {
                ...newValue, 
                kontaktadAvhamtning,
                acceptDate,
                disabledSaveSr
            }
            serverPost('/updateRow', '', '', {tableName, record, id:value.id}, handleSaveCallback)
        }
    }

    const removeEmptyVal = val => {   
        let newVal = {}
        Object.entries(val).map(it=> {
            if (it[1]) {

                newVal = {...newVal, [it[0]]:it[1]}
            }        
        })


        return newVal
    }

    const updateValueAfterSearch = rec =>
    {
        setTimeout(()=>window.scrollTo(0,0), 500)

        handleStatus({backgroundColor:'green'}, undefined)   
         
        setValue(removeEmptyVal(rec))
        // setList([])
    }

    const handleSearchReplyClickLine = list => {
        if (list.length === 0) {
            // handleStatus({backgroundColor:'orange'}, 'Inga rader hittades')    
        } else if (list.length === 1) {
            setGlobal({...global, pageName:'Servicerapport - Inlämnat objekt'})
            handleStatus({backgroundColor:'green'}, undefined)    
            const rec = list[0]
            updateValueAfterSearch(rec)
        } else {
            handleStatus({backgroundColor:'green'}, undefined)    
            setGlobal({...global, pageName:'Servicerapport - Sökning'})
            setList(list)
        }    
    }

    const handleSearchReply = list => {
        if (list.length === 0) {
            // handleStatus({backgroundColor:'orange'}, 'Inga rader hittades')    
        } else {
            handleStatus({backgroundColor:'green'}, undefined)    
            setGlobal({...global, pageName:'Servicerapport - Sökning'})
            setList(list)
        }    
    }

    const handleSearch = searchKeys => {
        search(searchView, searchKeys, handleSearchReply) 
    }

    const handleAdvancedSearch = searchKeys => {
        setList([])
        // handleStatus({backgroundColor:'green'}, 'Söker ...')    
        search2(searchView, searchKeys, handleSearchReply) 
    }


    const compareFunc = (v1, v2) => {
        if (!v1) {
            return 1
        } else if (!v2) {
            return -1
        } else if (Number.isInteger(v1)) {
            return v1 - v2
        } else if (isNaN(v1) || isNaN(v2)) {
            if (v1 && v2) {
                return v1.localeCompare(v2)
            } else {
                return 1
            }
        } else {
            return -1
        }
    }

    const handleSort = fld => {
        const name = fld.name 
        // alert('adam name=' + name + 'fld=' + JSON.stringify(fld) + ' length=' + list.length )
        const newList = list.length > 0?sortSequence[name]?list.sort((a,b)=>compareFunc(b[name], a[name])):list.sort((a,b)=>compareFunc(a[name], b[name])):list
        setList(newList)
        setSortSequence({...sortSequence, [name]:sortSequence[name]?undefined:true})
    }


    const componentRef = useRef();
    const componentRefA4 = useRef();

    const handleAvbryt = () => {
        navigate('/home')
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onBeforePrint: () => {
            handleSave()   
        }    
    });

    const handlePrintA4 = useReactToPrint({
        content: () => componentRefA4.current,
        onBeforePrint: () => {
            handleSave()   
        }    
    });

    const buttons=[
        {
            style:{color:'black', borderColor:'black'},
            label:'Skriv ut',
            required:true,
            handleClick:handlePrint
        },    
        {
            style:{color:'black', borderColor:'black'},
            label:'Skriv ut A4',
            required:true,
            handleClick:handlePrintA4
        },    
        {
            style:{color:'black', borderColor:'black'},
            type:'button',
            label:'Spara',
            required:true,
            disabled:edit?true:value.disabledSaveSr?value.disabledSaveSr==1?true:undefined:undefined,
            handleClick:()=>handleSave()
        },    
        /*
        {
            style:{color:'black', borderColor:'black'},
            type:'button',
            label:'Sök igen',
            handleClick:()=>setValue({})
        },
        */
        {
            style:{color:'black', borderColor:'black'},
            type:'button',
            label:'Avbryt',
            handleClick:handleAvbryt
        },

        /*
        {
            style:{color:'black', borderColor:'black'},
            type:'button',
            label:'Rensa',
            handleClick:handleReset
        },
        */    
    ]

    const formFieldsAdj = addGarantiAkutPrioritet(formFields, value)
    // const strValue = JSON.stringify(value)

    const handleClickSaveSearch = () => {
        setGlobal({...global, pageName:'Servicerapport - Sökning'})
        setValue({})
    }

    // {JSON.stringify(value)}
    return(
       <>    
        <PrintFields ref={componentRef} fields={fieldsServiceRapport} value={value} />
        <PrintA4 ref={componentRefA4}  value={value} />
        {value.id?
                <div  style={{marginTop:90, marginLeft:5, marginRight:5}}>
                    <div className='columns is-centered m-4'>
                        <div className="column is-3 ml-5" style={{}}>
                        <IconButton onClick={handleClickSaveSearch} >
                            <SavedSearchIcon style={{transform:"scale(1.8)", cursor:'pointer', zIndex:-1000}}/>    
                        </IconButton>
                        </div>
                        <div className="column" >
                                <RenderKunduppgifter 
                                                anchor={true} 
                                                akutPrioritet={true} 
                                                garanti={true} 
                                                value={value} 
                                                setValue={setValue} 
                                                edit={edit}
                                                setEdit={setEdit}
                                                disabled={disabledFunc1(value)}
                                />
                                <FormTemplate
                                        buttons={buttons}
                                        tableName={tableName} 
                                        fields={formFieldsAdj} 
                                        value={value} 
                                        setValue={setValue}
                                        handleStatus={handleStatus}  
                                />        
                        </div>    
                        <div className="column ">
                                <RenderFields A4={true} fields={fieldsExtra} value={value} setValue={setValue} /> 
                        </div>
                    </div>
                    <StatusMessage style={statusMessage.style} message={statusMessage.message} />
                </div>
        :
            <div  style={{marginTop:90, marginLeft:5, marginRight:5}}>
                <div className='columns is-centered'>
                    <div className='column is-10'>
                    {advancedSearch?
                    <SearchVatansever 
                        setValue={setValue} 
                        setList={setList} 
                        handleSearch={handleAdvancedSearch}
                        handleStatus={handleStatus}
                    />
                    :
                    <SearchTemplate 
                        searchView={searchView}
                        searchFields={searchFields}
                        setValue={setValue} 
                        setList={setList} 
                        handleSearch={handleSearch}
                        handleStatus={handleStatus}
                    />
                    }
                        
                    </div>
                </div>
                <div className='columns is-centered'>
                    <div className='column is-narrow has-text-centered'>
                    <EditTable 
                        tableName={tableName}
                        searchView={searchView} 
                        searchFields={searchFields}
                        resultFields={advancedSearch?resultFieldsAdvanced:resultFields}
                        list={list} 
                        setList={setList} 
                        handleStatus={handleStatus}  
                        handleSort={handleSort}
                        handleClickLine={handleClickLine}
                    />
                    </div>
                </div>
                <StatusMessage timeout={2000} style={statusMessage.style} message={statusMessage.message} />
            </div>
    }    
    </>
    )
} 